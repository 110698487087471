const state = () => ({
    isSidebarOpen: false,
    selected: localStorage.getItem('selected') || 'Dashboard',
    page: localStorage.getItem('page') || 'Dashboard'
});

const getters = {
    isSidebarOpen: (state) => state.isSidebarOpen,
    selected: (state) => state.selected,
    page: (state) => state.page
};

const actions = {
    toggleSidebar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
    setSelected({ commit }, selected) {
        commit('SET_SELECTED', selected)
    },
    setPage({ commit }, page) {
        commit('SET_PAGE', page)
    }
};

const mutations = {
    TOGGLE_SIDEBAR(state) {
        state.isSidebarOpen = !state.isSidebarOpen
    },
    SET_SELECTED(state, selected) {
        state.selected = selected
        localStorage.setItem('selected', selected)
    },
    SET_PAGE(state, page) {
        state.page = page
        localStorage.setItem('page', page)
    }
};

const sideBar = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default sideBar;

