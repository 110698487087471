import baseHttp from "@store/api.js";

const state = () => ({
    deleteDocumentModalValue: null,
    deletingDocumentItemId: null,

    deleteShippingLabelModalValue: null,
    deletingShippingLabelItemId: null,

    deleteModalValue: null,
    deletingItemId: null,
    deletingActionApi: 'delete',
    deletingText: null,
    pageData: [],
    showData: [],
    params: [],
});

const getters = {
    getDeleteDocumentModelValue: (state) => state.deleteDocumentModalValue,
    getDeleteShippingLabelModelValue: (state) => state.deleteShippingLabelModalValue,
    getDeleteModelValue: (state) => state.deleteModalValue,
    getDeletingText: (state) => state.deletingText,
    getPageData: (state) => state.pageData,
    getShowData: (state) => state.showData,
    getParams: (state) => state.params,
};

const actions = {
    async fetchPageData({commit}, params) {
        const res = await baseHttp.get('orders/fetch', {
            params
        });
        commit('SET_PAGE_DATA', res.data);
    },

    async show({commit}, params) {
        const res = await baseHttp.get('orders/show', {
            params
        });
        commit('SET_EDIT_DATA', res.data.data);
        return res.data.data;
    },

    async fetchParams({commit}, params) {
        const res = await baseHttp.get('orders/fetch-params', {params});
        commit('SET_PARAMS', res.data);
    },

    async delete({state}) {
        return await baseHttp.delete(`orders/${state.deletingActionApi}/${state.deletingItemId}`);
    },

    async update({}, params) {
        return await baseHttp.put(`orders/update`, params);
    },

    async addItem({}, params) {
        return await baseHttp.post(`orders/add-item`, params);
    },

    async addNote({}, params) {
        return await baseHttp.post(`orders/add-note`, params);
    },

    async refund({}, params) {
        return await baseHttp.post(`orders/refund`, params);
    },
    async showPdf({commit}, params) {
        const res = await baseHttp.get('orders/show-pdf', {
            params
        });

        return res.data;
    },

    async createShippingLabel({commit}, params) {
        const res = await baseHttp.post('orders/create-shipping-label', params);

        return res.data;
    },

    async createAdditionalLabel({commit}, params) {
        const res = await baseHttp.post('orders/create-additional-label', params);

        return res.data;
    },

    async deleteDocument({state}) {
        return await baseHttp.delete(`orders/delete-document/${state.deletingDocumentItemId}`);
    },

    async deleteShippingLabel({state}) {
        return await baseHttp.delete(`orders/delete-shipping-label/${state.deletingShippingLabelItemId}`);
    },

    async generate({}, params) {
        return await baseHttp.post(`orders/generate`, params);
    },
};

const mutations = {
    SET_DELETE_DOCUMENT_MODAL_VALUE(state, data) {
        if (data.value !== undefined) {
            state.deleteDocumentModalValue = data.value;
        }
        if (data.id !== undefined) {
            state.deletingDocumentItemId = data.id;
        }
    },
    SET_DELETE_SHIPPING_LABEL_MODAL_VALUE(state, data) {
        if (data.value !== undefined) {
            state.deleteShippingLabelModalValue = data.value;
        }
        if (data.id !== undefined) {
            state.deletingShippingLabelItemId = data.id;
        }
    },
    SET_DELETE_MODAL_VALUE(state, data) {
        if (data.value !== undefined) {
            state.deleteModalValue = data.value;
        }
        if (data.id !== undefined) {
            state.deletingItemId = data.id;
        }
        if (data.deletingActionApi !== undefined) {
            state.deletingActionApi = data.deletingActionApi;
            state.deletingText = data.deletingText;
        }
    },
    SET_PAGE_DATA(state, data) {
        state.pageData = data;
    },
    SET_EDIT_DATA(state, data) {
        state.showData = data;
    },
    SET_PARAMS(state, params) {
        state.params = params;
    },
};

const order = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default order;

