import Echo from "laravel-echo"
import io from 'socket.io-client';

window.io = io;
console.log(window.location.hostname);
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname + ':6001',
    disableStats: true,
    enabledTransports: ['ws', 'wss']
});

