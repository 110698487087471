const routes = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/vendors',
        redirect: 'vendors',
        children: [
            {
                path: '',
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/vendor/vendors/index.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/vendor/vendors/create.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/vendor/vendors/update.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'countries',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/vendor/countries/index.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/vendor/countries/create.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/vendor/countries/update.vue'),
                        meta: {
                            requiresAuth: true
                        }
                    }
                ]
            },
            {
                path: 'permissions',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/users/permissions/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'permissions',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'reviews',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/users/reviews/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'reviews',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/users/reviews/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'reviews',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/users/reviews/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'reviews',
                            permission_type: 'can_view',
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/dashboard',
        component: () => import('@pages/dashboard/index.vue'),
        meta: {
            requiresAuth: true,
            permission_name: 'dashboard',
            permission_type: 'can_view',
        }
    },
    {
        path: '/medias',
        component: () => import('@pages/media/index.vue'),
        meta: {
            requiresAuth: true,
            permission_name: 'medias',
            permission_type: 'can_view',
        }
    },
    {
        path: '/users',
        redirect: 'users/list',
        children: [
            {
                path: 'list',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/users/list/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'users',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/users/list/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'users',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/users/list/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'users',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'user-groups',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/users/userGroups/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'users_groups',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/users/userGroups/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'users_groups',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/users/userGroups/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'users_groups',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'permissions',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/users/permissions/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'permissions',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'reviews',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/users/reviews/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'reviews',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/users/reviews/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'reviews',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/users/reviews/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'reviews',
                            permission_type: 'can_view',
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/catalog',
        redirect: 'catalog/products',
        children: [
            {
                path: 'products',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/catalog/products/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'products',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/catalog/products/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'products',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/catalog/products/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'products',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'categories',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/catalog/categories/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'categories',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/catalog/categories/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'categories',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/catalog/categories/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'categories',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'attribute-types',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/catalog/attributeTypes/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'attribute_types',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/catalog/attributeTypes/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'attribute_types',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/catalog/attributeTypes/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'attribute_types',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'attributes',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/catalog/attributes/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'attributes',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/catalog/attributes/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'attributes',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/catalog/attributes/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'attributes',
                            permission_type: 'can_view',
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/warehouse',
        redirect: 'warehouse/items',
        children: [
            {
                path: 'items',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/warehouse/items/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'items',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
        ]
    },
    {
        path: '/orders',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                component: () => import('@pages/orders/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'orders',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'show/:id',
                component: () => import('@pages/orders/show.vue'),
                name: 'order_show',
                meta: {
                    requiresAuth: true,
                    permission_name: 'orders',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'show-pdf/:id/:base?',
                component: () => import('@pages/orders/show-pdf.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'orders',
                    permission_type: 'can_view',
                }
            }
        ]
    },
    {
        path: '/contents',
        redirect: 'contents/pages',
        children: [
            {
                path: 'menus',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        name: 'menus',
                        component: () => import('@pages/contents/menu/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'menus',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create/:languageId/:type',
                        component: () => import('@pages/contents/menu/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'menus',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId/:type',
                        component: () => import('@pages/contents/menu/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'menus',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'pages',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/contents/pages/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'pages',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create/:languageId',
                        component: () => import('@pages/contents/pages/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'pages',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/contents/pages/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'pages',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'post-categories',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/contents/postCategories/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'posts',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create/:languageId',
                        component: () => import('@pages/contents/postCategories/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'posts',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/contents/postCategories/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'posts',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'posts',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/contents/posts/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'posts',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create/:languageId',
                        component: () => import('@pages/contents/posts/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'posts',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/contents/posts/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'posts',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'a-plus-contents',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/contents/aPlusContents/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'a_plus_contents',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create/:languageId',
                        component: () => import('@pages/contents/aPlusContents/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'a_plus_contents',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/contents/aPlusContents/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'a_plus_contents',
                            permission_type: 'can_view',
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/marketing',
        redirect: '/marketing/coupons',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'coupons',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/marketing/coupon/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'coupons',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/marketing/coupon/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'coupons',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/marketing/coupon/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'coupons',
                            permission_type: 'can_view',
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/settings',
        redirect: '/settings/general',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'general',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/settings/general/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'general_settings',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/settings/general/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'general_settings',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'store-countries',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/settings/storeCountries/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'store_countries',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'update/:id/',
                        component: () => import('@pages/settings/storeCountries/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'store_countries',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'media-settings',
                component: () => import('@pages/settings/mediaSetting/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'media_settings',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'tax',
                component: () => import('@pages/settings/tax/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'tax',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'shipping-zones',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/settings/shippingZone/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'shipping_zones',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create/:languageId',
                        component: () => import('@pages/settings/shippingZone/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'shipping_zones',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/settings/shippingZone/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'shipping_zones',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'currencies',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/settings/currencies/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'currencies',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/settings/currencies/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'currencies',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/settings/currencies/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'currencies',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'socials',
                component: () => import('@pages/settings/socials/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'socials_settings',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'shipping-label-settings',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/settings/shippingLabelSetting/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'shipping_label_settings',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'update/:id',
                        component: () => import('@pages/settings/shippingLabelSetting/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'shipping_label_settings',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'languages',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/settings/languages/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'languages',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/settings/languages/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'languages',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:code',
                        component: () => import('@pages/settings/languages/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'languages',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
            {
                path: 'translations',
                component: () => import('@pages/settings/translation/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'translations',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'permalinks',
                component: () => import('@pages/settings/permalinks/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'permalinks',
                    permission_type: 'can_view',
                }
            }
        ]
    },
    {
        path: '/accounting',
        redirect: '/accounting/files',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'files',
                component: () => import('@pages/accounting/files/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'accounting_files',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'settings',
                component: () => import('@pages/accounting/settings/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'accounting_settings',
                    permission_type: 'can_view',
                }
            },
        ]
    },
    {
        path: '/document-settings',
        redirect: '/document-settings/general',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'general/:id/:languageId',
                component: () => import('@pages/documentSettings/general/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'document_settings_general',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'individual',
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/documentSettings/individual/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'document_settings_individual',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/documentSettings/individual/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'document_settings_individual',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
        ]
    },
    {
        path: '/tools',
        redirect: '/tools/file-uploads',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'file-uploads',
                component: () => import('@pages/tools/uploads/index.vue'),
                meta: {
                    requiresAuth: true,
                    permission_name: 'uploads',
                    permission_type: 'can_view',
                }
            },
            {
                path: 'calculators',
                meta: {
                    requiresAuth: true
                },
                children: [
                    {
                        path: '',
                        component: () => import('@pages/tools/calculators/index.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'calculators',
                            permission_type: 'can_view',
                        }
                    },
                    {
                        path: 'create/:languageId',
                        component: () => import('@pages/tools/calculators/create.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'calculators',
                            permission_type: 'can_add',
                        }
                    },
                    {
                        path: 'update/:id/:languageId',
                        component: () => import('@pages/tools/calculators/update.vue'),
                        meta: {
                            requiresAuth: true,
                            permission_name: 'calculators',
                            permission_type: 'can_view',
                        }
                    }
                ]
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@pages/auth/Login.vue'), // Lazy loading
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/:pathMatch(.*)*', // Catch-all route for 404
        component: () => import('@pages/NotFound.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/forbidden',
        component: () => import('@pages/NotFound.vue'),
        meta: {
            requiresAuth: true,
            permission_name: 'forbidden',
        }
    }
];

export default routes;
