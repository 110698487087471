<script setup>
import Loading from '@/components/global/Loading.vue'
import {ref, watch} from "vue";
import {useStore} from "vuex";

const store = useStore()
const echoHasWorked = ref(false);

watch(
    () => store.getters['auth/getUser'],
    (newValue) => {
        if (!echoHasWorked.value && newValue) {
            echoHasWorked.value = true;
            Echo.private('new-order.' + newValue.id)
                .listen('NewOrder', (event) => {
                    console.log('Socket got event from channel: new order');
                    store.commit('notification/SET_NEW_PUSH_NOTIFICATION', {
                        link: event.link,
                        show: event.show,
                        text: event.text,
                        datetime: event.datetime,
                    })
                });
        }
    },
    {immediate: true}
);
</script>

<template>
    <Loading/>
    <router-view></router-view>
</template>

<style scoped>

</style>
